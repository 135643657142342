import store from '../store'

export default [
  {
    path: '/',
    name: 'default',
    meta: {
      title: 'Login',
      beforeResolve (routeTo, routeFrom, next) {
        if (store.getters.loggedIn) {
          next({ name: 'dashboard' })
          next()
        } else {
          next()
        }
      }
    },
    component: () => import('../views/auth/LoginView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'Dashboard',
      authRequired: true
    },
    component: () => import('../views/analytics/DashboardView.vue')
  },
  {
    path: '/branches',
    name: 'branches',
    meta: {
      title: 'Branches',
      authRequired: true
    },
    component: () => import('../views/branches/indexView.vue')
  },
  {
    path: '/stock-branch/:id',
    name: 'Branch Stocks',
    component: () => import('../views/branches/branch_stocks/branchStock.vue'),
    meta: {
      title: 'Branch Stocks',
      authRequired: true
    }
  },
  {
    path: '/add-branch-stocks/:id',
    name: 'Add Branch Stocks',
    component: () => import('../views/branches/branch_stocks/branchAddStockInventory.vue'),
    meta: {
      title: 'Add Branch Stocks',
      authRequired: true
    }
  },
  {
    path: '/sales-branch/:id',
    name: 'Branch Sales',
    component: () => import('../views/branches/branch_sales/branchSales.vue'),
    meta: {
      title: 'Branch Sales',
      authRequired: true
    }
  },
  {
    path: '/items',
    name: 'items',
    meta: {
      title: 'Product Items',
      authRequired: true
    },
    component: () => import('../views/items/items-list/indexView.vue')
  },
  {
    path: '/sort-items',
    name: 'sort-item',
    meta: {
      title: 'Sort Product Items',
      authRequired: true
    },
    component: () => import('../views/items/sort-items/sortItemIndex.vue')
  },
  {
    path: '/deleted-items',
    name: 'deleted_items',
    meta: {
      title: 'Deleted Items',
      authRequired: true
    },
    component: () => import('../views/items/deleted-items/deletedItemsIndex.vue')
  },
  {
    path: '/suppliers',
    name: 'Suppliers',
    component: () => import('../views/suppliers/indexView.vue'),
    meta: {
      title: 'Suppliers',
      authRequired: true
    }
  },
  {
    path: '/purchases',
    name: 'Puchases',
    component: () => import('../views/purchases/indexView.vue'),
    meta: {
      title: 'Purchases',
      authRequired: true
    }
  },
  {
    path: '/supply-request',
    name: 'Supply Request',
    component: () => import('../views/supply-request/stock_request/indexView.vue'),
    meta: {
      title: 'Other Requests',
      authRequired: true
    }
  },
  {
    path: '/stock-receivables',
    name: 'Stock Receivables',
    component: () => import('../views/stock-receivables/indexView.vue'),
    meta: {
      title: 'Stock Receivables',
      authRequired: true
    }
  }, {
    path: '/other-requests',
    name: 'Other Requests',
    component: () => import('../views/supply-request/other-requests/indexView.vue'),
    meta: {
      title: 'Other Requests',
      authRequired: true
    }
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('../views/warehouse/indexView.vue'),
    meta: {
      title: 'warehouse',
      authRequired: true
    }
  },
  {
    path: '/warehouse-stocks/:id',
    name: 'Warehouse Stocks',
    component: () => import('../views/warehouse/warehouse_stocks/warehouseStock.vue'),
    meta: {
      title: 'Warehouse Stocks',
      authRequired: true
    }
  },
  {
    path: '/add-warehouse-stocks/:id',
    name: 'Add Warehouse Stocks',
    component: () => import('../views/warehouse/warehouse_stocks/addWarehouseStocks.vue'),
    meta: {
      title: 'Add Warehouse Stocks',
      authRequired: true
    }
  },

  {
    path: '/drivers',
    name: 'drivers',
    component: () => import('../views/logistics/driver/driversIndex.vue'),
    meta: {
      title: 'Drivers',
      authRequired: true
    }
  },
  {
    path: '/trucks',
    name: 'trucks',
    component: () => import('../views/logistics/truck/trucksIndex.vue'),
    meta: {
      title: 'Trucks',
      authRequired: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/users/indexView.vue'),
    meta: {
      title: 'Users',
      authRequired: true
    }
  },
  {
    path: '/reg',
    name: 'registration',
    component: () => import('../views/auth/RegistrationView.vue'),
    meta: {
      title: 'Registrations'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/auth/LoginView.vue')
  }
]
