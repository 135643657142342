import axios from 'axios'
import store from './store'

const baseUrlTest = 'https://prdback.metacoresystemsinc.com/api/'
const baseUrlLocal = 'http://localhost:8080/api/'

console.log(baseUrlTest)
console.log(baseUrlLocal)

const axiosInstance = axios.create({
  baseURL: baseUrlTest
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.state.accessToken
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
